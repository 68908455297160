<template>
    <div class="p-relative">

        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="false"
            />
        </Toolbar>
        
        <v-row no-gutters>

            <v-col cols="12" xs="12" md="12">
                <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="entities"
                    :item-class="itemRowBackground"
                    :options.sync="options"
                    :server-items-length="total"
                    :items-per-page="10"
                    :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                    :page.sync="options.page"
                    @page-count="paginationPageCount = $event"
                    class="elevation-1 customization-list-table pagination-list-table sticky-table"
                    @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Documents|Orders|CitizenStatements.Resolution' })">
                    <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                    <template v-slot:[`item.regnumber`]="{ item }">
                        <v-document-link
                            :id="item.docid"
                            :type="'Chancellery|Documents.Document'"
                            :text="item.regnumber"
                        />
                    </template>

                    <template v-slot:[`item.regdate`]="{ item }">
                        <div>{{ $moment(item.regdate).format('DD.MM.YYYY HH:mm:ss') }}</div>
                    </template>

                    <template v-slot:[`item.resolutiontext`]="{ item }">

                        <div class="elipsis-text-wrap">

                            <v-doc-type-chip
                                type="Resolution"
                                :text="$t('Резолюция')"
                            />

                            <div class="etw-text-container">
                                <div class="etw-text" v-tooltip.top-center="item.resolutiontext">
                                    {{ item.resolutiontext }}
                                </div>
                            </div>
                        </div>

                    </template>

                    <template v-slot:[`item.authorname`]="{ item }">
                        <v-employee-chip v-if="item.authorname" :id="item.authorid" :name="item.authorname" />
                    </template>

                    <template v-slot:[`footer.prepend`]>
                        <v-pagination
                            v-model="options.page"
                            :length="paginationPageCount"
                            :total-visible="$listPaginationTotal"
                            color="teal"
                        ></v-pagination>
                    </template>

                </v-data-table>
            </v-col>

        </v-row>

    </div>
</template>

<script>
import _ from "lodash";
import { httpAPI } from "@/api/httpAPI";

import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/documents/SearchPanel.vue';

export default {
    name: "ChancelleryResolutions",
    columnIndex:  {
        'regnumber': 3,
        'regdate': 4,
        'resolutiontext': 5,
        'authorname': 6
    },
    components: {
        Toolbar,
        SearchPanel
    },
    computed: {
        headers() {
            return [
                { text: this.$t("Номер_документа"), value: 'regnumber', width: '150px' },
                { text: this.$t("Рег._дата"), value: 'regdate', width: '150px' },
                { text: this.$t("Краткое_содержание"), value: 'resolutiontext'},
                { text: this.$t("Автор"), value: 'authorname', width: '150px' },
            ];
        },
        search: {
            get: function() {
                return this.$store.getters['chancellery/lists/resolutions/getSearch']
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();

                this.$store.commit('chancellery/lists/resolutions/SET_SEARCH', v );
            }, 500)
        },
        options: {
            get: function() {
                return this.$store.getters['chancellery/lists/resolutions/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('chancellery/lists/resolutions/SET_TABLE_OPTION', newValue);
            }
        },
        filter() {
            return {
                ...this.options,
                SearchStr: this.search
            }
        },
    },
    data() {
        return {
            collection: "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument",
            loading: false,

            entities: [],
            total: 0,

            paginationPageCount: 0
        }
    },
    methods: {
        itemRowBackground (item) {
            return item.readed ? '' : 'font-weight-bold';
        },
        onToolbarClick(e, btn) {
            let method = this[btn.Action];

            if (method)
                method(btn);
            else
                console.log(`${btn.Action} method not implemented!`);
        },
        async Refresh() {
            await this.loadCollectionData();
            await this.getData(true);
        },
        async loadCollectionData() {
            let response = await httpAPI({
                url: `api/actions/collection?type=Chancellery.Document`,
                method: 'GET'
            });
            
            if (response) {
                this.$store.commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu });
            }
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            
            let response = await httpAPI({
                url: `/api/chancellary/resolutionslist?filter=${this.getTableFilterObject()}`,
                method: 'GET'
            });

            this.entities = response?.data?.Payload?.data ?? [];
            this.total = response?.data?.Payload?.total ?? 0;            
            this.loading = false;
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? true : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 4;

            return JSON.stringify({ SearchStr, OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage });
        }
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
    },
    async created () {
        await this.loadCollectionData();
    }
}
</script>