<template>

    <div class="top-btns-bar">

        <div class="transparent-buttons">
            <v-btn
                v-for="(item, index) in getMenu"
                :key="item.Action + index"
                @click="onToolbarButtonClick($event, item)"
                small
                tile
                text
                :disabled="btnIsLock(item.Action)"
            >
                <v-popover :trigger="item.Items ? 'click' : 'hover'">
                    <v-icon left>
                        {{mapIcon(item.Icon)}}
                    </v-icon>
                    <template v-if="item.Localize">
                        {{ $t(item.Text) }}
                    </template>
                    <template v-else>
                        {{ item.Text }}
                    </template>

                    <template slot="popover">
                        <ul class="ribbon-button-submenu" v-if="item.Items">
                            <li
                                v-for="(itemSub, indexSub) in item.Items"
                                :key="itemSub.Action + indexSub"
                                @click="onToolbarButtonClick($event, itemSub)"
                                v-close-popover
                            >
                                <v-icon left>
                                    {{ mapIcon(itemSub.Icon) }}
                                </v-icon>
                                <template v-if="itemSub.Localize">
                                    {{ $t(itemSub.Text) }}
                                </template>
                                <template v-else>
                                    {{ itemSub.Text }}
                                </template>
                            </li>
                        </ul>
                        <template v-if="item.Localize">
                            <span class="ribbon-button-submenu-title">{{ $t(item.ToolTip) }}</span>
                        </template>
                        <template v-else>
                            <span class="ribbon-button-submenu-title">{{ item.ToolTip }}</span>
                        </template>                        
                    </template>
                    <i class="fas fa-caret-down ribbon-button-has-ch" v-if="item.Items"></i> 
                </v-popover>
            </v-btn>

        </div>

        <slot>
        </slot>


    </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: "Toolbar",
    props: {
        isList: {
            type: Boolean,
            default: false
        },
        hideCreateButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            icons: {
                document_add: "far fa-file",
                print: "far fa-print",
                edit: "far fa-edit",
                attach_add: "far fa-paperclip",
                doc_begin_adjustment: "far fa-share-square",
                sign_send: "far fa-file-signature",
                doc_register_send: "far fa-external-link-alt",
                doc_send_link: "far fa-external-link-alt",
                copy: "far fa-copy",
                document_link: "far fa-external-link-square-alt",
                doc_delete: "far fa-trash",
                exit_editing: "far fa-arrow-left",
                save: "far fa-save",
                save_and_close: "far fa-times-circle",
                doc_set_adjustment: "far fa-handshake",
                doc_send: "far fa-paper-plane",
                sign: "far fa-signature",
                sign_add: "far fa-file-signature",
                sign_verify: "far fa-file-contract",
                process_start: "far fa-project-diagram",
                send_to_view: "far fa-eye",
                resolution_add: "far fa-marker",
                doc_register: "far fa-list-ol",
                doc_reject: "far fa-undo",
                remove_answer: "far fa-reply",
                execute_statement: "far fa-edit",
                doc_register_nonum: "far fa-file-prescription",
                doc_attach: "far fa-file-alt",
                doc_take_on_control: "far fa-stopwatch",
                resolution_prolongate: "far fa-calendar-alt",
                change_executer: "far fa-exchange-alt",
                doc_take_off_control: "far fa-flag-checkered",
                act_item_add: "far fa-plus-square",
                refresh: "far fa-sync-alt",
                make_report: "far fa-search",
                doc_reject_cancel: "far fa-trash-restore",
                document: "far fa-file",
                execution_resend: "far fa-share-square",
                execution_send: "far fa-check-circle",
                reserve: "far fa-tasks",
                doc_register_cancel: "far fa-undo",
                change_control: "far fa-pen-square",
                prolongate_control: "far fa-calendar-day",
                add: "far fa-plus",
                group_add: "far fa-plus",
                person_add: "far fa-plus",
                contact_add: "far fa-plus",
                enterprise: "far fa-landmark",
                person: "far fa-user",
                move_to_euol: "far fa-external-link-alt",
                change_number: "far fa-exchange-alt",
                change_nomenclature: "far fa-exchange-alt",
                withdraw_adjustment: "far fa-comment-slash",
                doc_return: "far fa-reply-all",
                set_inspected: "far fa-check",
                accept_invoice: "far fa-file-invoice-dollar",
                add_invoice: "far fa-file-invoice",
                execution_closed: "far fa-user-check",
                del: "far fa-undo",
                add_comment: "far fa-comment",
                make_contract: "far fa-file-contract",
                contract_send_to_sign: "far fa-file-signature",
                lockuser: "far fa-lock",
                unlockuser: "far fa-lock-open",
                send_eecb: "far fa-paper-plane",
                disconnect: "far fa-user-slash",
                delemployee: "far fa-user-slash",
                reset_pas: "far fa-cogs",
                move_to_archive: 'far fa-box',
                move_from_archive: 'far fa-box-open',
                add_section_answer: 'far fa-external-link-square-alt',
                CancelExecution: 'far fa-undo',
                send_exec: 'far fa-vote-yea',
                edit_recip_list: 'far fa-unlock-alt',
                reportform_send: 'far fa-file-export'
            }
        }
    },
    computed:{
        ...mapGetters('documents/toolbar', ['isToolbarLock', 'lockedActions', 'getMenu']),
    },    
    methods: {
        ...mapActions(['setOverlayVisible']),
        mapIcon (source) {
            return this.icons[source] ?? "fas fa-ban";
        },
        btnIsLock(btnAction){
            return this.isToolbarLock || this.lockedActions.indexOf(btnAction) > -1;
        },
        onToolbarButtonClick(event, button) {
            if (!button.Items)
                this.$emit('toolbar-button-click', event, button);
        },
        stickyFPanel() {
            var parent = document.getElementsByClassName('content-box')[0];
            var top_btns_bar_obj = parent.getElementsByClassName('top-btns-bar');
            var white_panel_obj = parent.getElementsByClassName('white-panel-items');
            var tabs_card_btn_obj = parent.getElementsByClassName('tab-selector-panel');
            var after_tabs_card_btn_obj = parent.getElementsByClassName('after-tabs-part-wrapper');

            var toolbar_h = top_btns_bar_obj.length > 0 ? top_btns_bar_obj[0].clientHeight : 0;
            var white_panel_h = white_panel_obj.length > 0 ? white_panel_obj[0].clientHeight : 0;
            var tabs_margin_top = white_panel_obj.length > 0 ? 10 : 0;

            if (white_panel_obj.length > 0) {
                white_panel_obj[0].style.top = toolbar_h + "px";
            }

            if (after_tabs_card_btn_obj.length > 0) {
                after_tabs_card_btn_obj[0].style.top = (toolbar_h + white_panel_h + tabs_margin_top) + "px";
            }
            else {
                if (tabs_card_btn_obj.length > 0) {
                    tabs_card_btn_obj[0].style.top = (toolbar_h + white_panel_h + tabs_margin_top) + "px";
                }
            }
        }
    },
    mounted() {
        this.$nextTick(this.stickyFPanel);
        window?.addEventListener('resize', this.stickyFPanel);
    },
    watch: {
        menu() {
            this.$nextTick(function() {
                this.stickyFPanel();
            });
        }
    },
    beforeDestroy() {
        window?.removeEventListener('resize', this.stickyFPanel);
    }
}
</script>